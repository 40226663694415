<template>
  <main class="main page-inside">
    <div class="main-wrapper">
      <header-wrapper class="section hero-section">
        <div class="wrapper">
          <div class="content">
            <div class="card-list">
              <template v-if="!$parent.checkoutSuccess">
                <div class="card-list__item order-details">
                  <div class="title small">{{ $t(`Order details`) }}</div>
                  <div class="desc">{{ $t(`Choose payment method`) }}:</div>
                  
                  <div class="radio-list">
                    <div class="list">


                      <template v-if="!$parent.fiatToCrypto">
                        <div class="item"></div>
                        <div class="item">
                          <label>
                            <input type="radio" v-model="$parent.paymentMethod" name="paymentMethod" value="crypto" />
                            <div class="text">
                              <div class="desc">Crypto</div>
                            </div>
                          </label>
                        </div>
                      </template>
                      <div v-else v-for="method in $parent.paymentMethods" :key="method.code" class="item">
                        <label>
                          <input type="radio" v-model="$parent.paymentMethod" name="paymentMethod" :value="method.code" />
                          <div class="text">
                            <img v-if="method.image" :src="$parent.appDomain + method.image" class="img" :class="method.code === 'paysafecard' || method.title === null ? 'paysafecard' : ''" />
                            <div class="desc">{{ method.title }}</div>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="input-container">
                    <div class="desc">{{ $t(`Wallet:`) }}</div>
                    <input type="text" v-model="wallet" />
                    <div class="clue">{{ $t(`The wallet you'll pay from`) }}</div>
                  </div>
                  <div class="total">
                    <div class="desc">{{ $t(`Total amount to pay:`) }}</div>
                    <div class="total-wrapper">
                      <div class="item">
                        <div class="text">
                          <div class="desc">{{ $t(`Date of order`) }}</div>
                          <div class="desc">{{ currentDate }}</div>
                        </div>
                        <div class="title center" :class="{ 'crypto': !$parent.fiatToCrypto }">{{ leftAmount }}</div>
                      </div>
                      <div class="item">
                        <div class="text">
                          <div class="desc">{{ $t(`Exchange rate`) }}</div>
                          <div class="desc">{{ $parent.takeCurrency.crypto_currency }} = {{ $parent.takeCurrency.price }}
                            {{ $parent.giveCurrency.symbol }}</div>
                        </div>
                        <div class="title center" :class="{ 'crypto': $parent.fiatToCrypto }">{{ rightAmount }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="total-bottom">
                    <router-link to="buy-crypto" class="button gray">{{ $t(`Cancel order`) }}</router-link>
                    <button class="button" @click="submit">{{ $t(`Continue`) }}</button>
                  </div>
                </div>
                <div class="card-list__item-container">
                  <div class="card-list__item your-card">
                    <div class="title small">{{ $t(`Your Card`) }}</div>
                    <div class="row">
                      <div class="title center">{{ leftAmount }}</div>
                      <router-link to="buy-crypto" class="button gray">{{ $t(`Change amount`) }}</router-link>
                    </div>
                    <div class="desc">{{ rightAmount }}</div>
                    <div class="table">
                      <div class="row">
                        <div class="desc">{{ $t(`Exchange rate`) }}</div>
                        <div class="desc">1 {{ $parent.takeCurrency.crypto_currency }} = {{ $parent.takeCurrency.price }}
                          {{ $parent.giveCurrency.symbol }}</div>
                      </div>
                      <div class="row">
                        <div class="desc">{{ $parent.fiatToCrypto ? 'Coins' : 'Money' }} to receive</div>
                        <div class="desc">{{ rightAmount }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="timer">
                    <div class="title center white">{{ hours }}:{{ minutes }}:{{ seconds }}</div>
                    <div class="desc">{{ $t(`The price is fixes for 1 hour`) }}</div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="card-list__item order-success">
                  <div class="order-submit-success">
                    <div class="order-submit-success__title">{{ $t(`Order successfully submitted`) }}</div>
                    <div class="order-submit-success__message">
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </header-wrapper>
    </div>
  </main>
</template>
<script>

import fiatSection from "@/components/FiatSection.vue";
import HeaderWrapper from "@/components/HeaderWrapper.vue";

export default {
  name: 'OrderDetails',
  props: [],
  components: {
    HeaderWrapper
  },
  data: function () {
    return {
      paymentMethod: 'visaMastercard',
      wallet: '',
      hours: 1,
      minutes: 0,
      seconds: 0,
      timer: null,
      currentDate: '',
      submitSuccess: false
    }
  },
  mounted: function () {
    this.$parent.changeCheckoutSuccess(false);
    this.startTimer();
    this.getCurrentDate();
  },
  methods: {
    getCurrentDate() {
      const today = new Date();
      const formattedDate = today.toISOString().split('T')[0];

      this.currentDate = formattedDate;
    },
    startTimer() {
      this.timer = setInterval(() => {
        if (this.hours === 0 && this.minutes === 0 && this.seconds === 0) {
          clearInterval(this.timer);
        } else {
          if (this.seconds > 0) {
            this.seconds--;
          } else {
            if (this.minutes > 0) {
              this.minutes--;
              this.seconds = 59;
            } else {
              this.hours--;
              this.minutes = 59;
              this.seconds = 59;
            }
          }
        }
      }, 1000);
    },
    submit() {
      this.$parent.openPersonalInfoModal();
      this.$emit('setWallet', this.wallet)
    }
  },
  computed: {
    fiatSection() {
      return fiatSection
    },
    leftAmount() {
      const parent = this.$parent;
      return parent.fiatToCrypto ? `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount).toFixed(2)}` : `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}`;
    },
    rightAmount() {
      const parent = this.$parent;
      return parent.fiatToCrypto ? `${parent.takeAmount} ${parent.takeCurrency.crypto_currency}` : `${parent.giveCurrency.symbol} ${parseFloat(parent.giveAmount).toFixed(2)}`;
    }
  }
}
</script>