<template>
  <footer class="footer">
    <div class="wrapper">
      <div class="content">
        <div class="logo-icons">
          <div class="logo">
            <img src="./../assets/logo2.svg" class="img" />
          </div>
          <div class="icons">
            <a :href="link.link" v-for="link in $parent.socialLinks" class="link" v-bind:key="link.link"
              target="_blank">
              <img :src="link.img" />
            </a>
          </div>
        </div>

        <div class="links">
          <div v-for="item in $parent.links.slice(0, 3)" :key="item.title" class="links__item">
            <router-link v-if="item.path" :to="item.path">
              {{ item.title }}
            </router-link>
            <a v-else @click="$parent.goToPage(item)">
              {{ item.title }}
            </a>
          </div>
        </div>

        <div class="links">
          <div v-for="item in $parent.links.slice(3)" :key="item.title" class="links__item">
            <router-link v-if="item.path" :to="item.path">
              {{ item.title }}
            </router-link>
            <a v-else @click="$parent.goToPage(item)">
              {{ item.title }}
            </a>
          </div>
        </div>

        <div class="legal-info">
          <div class="legal-info__info">{{ $parent.legalInfo }}</div>
          <div class="legal-info__vsmc">

            <!-- <img :src="$parent.appDomain + method.image" v-for="method in paymentMethodsList" :key="method.code"
              class="payment-method img" /> -->
            <ul class="payment-methods-list" v-if="paymentMethodsList && paymentMethodsList.length">
              <li v-for="image in allImages" :key="image" class="image-wrapper">
                <img :src="image" class="support-icon img" />
              </li>
              <li class="image-wrapper">
                <img src="@/assets/paysafecard.png" class="support-icon img" />
              </li>
            </ul>
            <!-- <img src="../images/mc.svg" alt="">
            <img src="../images/visa-logo.svg" alt=""> -->
          </div>
        </div>
      </div>
      <div class="copyright">
        © {{ new Date().getFullYear() }} All Rights Reserved
      </div>
    </div>

    <!--		<div class="wrapper">-->
    <!--			<ul class="nav">-->
    <!--				<template  v-for="item in $parent.footerNav">-->
    <!--					<li class="nav__item" :key="item.title">-->
    <!--						<router-link class="desc" :to="item.path">-->
    <!--							{{item.title}}-->
    <!--						</router-link>-->
    <!--					</li>-->
    <!--				</template>-->
    <!--				<template  v-for="item in $parent.textPageList">-->
    <!--					<li class="nav__item" :key="item.id">-->
    <!--						<a class="desc" @click="$parent.goToPage(item)">-->
    <!--						{{item.title}}-->
    <!--						</a>-->
    <!--					</li>-->
    <!--				</template>-->
    <!--			</ul>-->
    <!--			<div v-if="hasCountryObject" class="nav__item allowed-countries">-->
    <!--				<a @click="$parent.goToAllowedCountries" class="desc">-->
    <!--					Allowed countries-->
    <!--				</a>-->
    <!--			</div>-->
    <!--		</div>-->
    <!--    <div class="wrapper wrapper_social">-->
    <!--      <div class="social-links">-->
    <!--        <div v-for="link in $parent.socialLinks" :key="link.img" class="social_links__link">-->
    <!--          <a :href="link.link">-->
    <!--            <img :src="link.img" :alt="link.link"/>-->
    <!--          </a>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--		<div class="wrapper">-->
    <!--			<div v-if="$parent.legalInfo" class="desc">{{$parent.legalInfo}}</div>-->
    <!--			<div class="list">-->
    <!--				<div class="item" v-if="$parent.phone">-->
    <!--					<div class="desc">{{ $t(`Phone`) }}:</div>-->
    <!--					<a :href="'tel:' + $parent.phone" class="desc">{{$parent.phone}}</a>-->
    <!--				</div>-->
    <!--				<div class="item" v-if="$parent.email">-->
    <!--					<div class="desc">{{ $t(`E-mail:`) }}</div>-->
    <!--					<a :href="'mailto:' + $parent.email" class="desc">{{$parent.email}}</a>-->
    <!--				</div>-->
    <!--			</div>-->
    <!--		</div>-->
    <!--		<div class="wrapper">-->
    <!--			<div class="desc">© 2023 All Rights Reserved</div>-->
    <!--			<ul class="list">-->
    <!--				<li class="item">-->
    <!--				<img src="./../images/mc.svg" class="img"/>-->
    <!--				</li>-->
    <!--				<li class="item">-->
    <!--				<img src="./../images/visa.svg" class="img"/>-->
    <!--				</li>-->
    <!--				<li class="item">-->
    <!--				<img src="./../images/3ds.png" class="img"/>-->
    <!--				</li>-->
    <!--			</ul>-->
    <!--		</div>-->
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  props: [],
  data: function () {
    return {
      paymentMethods: [],
      // legalInfo: '',
      // email: '',
      // phone: ''
    }
  },
  methods: {
    getImages(method) {
      const images = [];
      if (method.code === 'card' && method.title === 'Visa/Mastercard') {
        images.push(require('@/assets/visa.svg'), require('@/assets/mc_symbol.svg'));
        // images.push(require('@/assets/3ds.png'));
      } else if (method.code === 'eps' || method.title === 'EPS') {
        images.push(require('@/assets/eps.svg'));
      } else if (method.code === 'trustly' || method.title === 'Trustly') {
        images.push(require('@/assets/trustly.svg'));
      } else if (method.code === 'giropay' || method.title === 'GiroPay') {
        images.push(require('@/assets/giropay.svg'));
      } else if (method.code === 'blik' || method.title === 'Blik') {
        images.push(require('@/assets/blik.svg'));
      } else if (method.code === 'paidby' || method.title === 'Paidby') {
        images.push(require('@/assets/paidby.svg'));
      } else if (method.code === 'klarna' || method.title === 'Klarna') {
        images.push(require('@/assets/klarna.svg'));
      } else if (method.code === 'sofortuberweisung' || method.title === 'Sofortuberweisung') {
        images.push(require('@/assets/sofortuber.svg'));
      } else if (method.code === 'ideal' || method.title === 'iDEAL') {
        images.push(require('@/assets/ideal.svg'));
      } else if (method.code === 'bancontact' || method.title === 'Bancontact') {
        images.push(require('@/assets/bancontact.svg'));
      } 
      // else if (method.code === 'paysafecard' || method.title === 'Paysafecard') {
      //   images.push(require('@/assets/paysafecard.png'));
      // }

      return images;
    }
  },
  computed: {
    paymentMethodsList() {
      if (!this.paymentMethods) return [];

      const flatPaymentMethods = [].concat(...Object.values(this.paymentMethods));

      const uniquePaymentMethodsMap = new Map();
      flatPaymentMethods.forEach(pm => {
        const uniqueKey = `${pm.title}-${pm.code}`;
        if (!uniquePaymentMethodsMap.has(uniqueKey)) {
          uniquePaymentMethodsMap.set(uniqueKey, pm);
        }
      });

      const uniquePaymentMethods = Array.from(uniquePaymentMethodsMap.values());
      return uniquePaymentMethods;
    },
    allImages() {
      return this.paymentMethodsList.flatMap(this.getImages);
    },
    hasCountryObject() {
      return this.$parent.textPageList.some(obj => obj.hasOwnProperty("is_countries") && obj.is_countries === 1);
    },
  },
  mounted() {
    this.$http.get('/payment-methods').then((response) => {
			this.paymentMethods = response.data.payload.payment_methods;
		});
    // this.$http.get(process.env.VUE_APP_API + 'settings')
    // .then((res) => {
    // 	this.legalInfo = res.data.payload.legal_info;
    // 	this.email = res.data.payload.support_email;
    // 	this.phone = res.data.payload.support_phone;
    // })
    // .catch(() => {
    //
    // })
  }
}
</script>

<style lang="scss" scoped>
.footer .payment-methods-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  .image-wrapper {
    /* background-color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    max-width: 70px;
  }

  .support-icon.img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
    display: block;
  }
}
</style>