import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from './pages/PageNotFound.vue'
import Home from './pages/Home.vue'
import Profile from './pages/Profile.vue'
import PaymentSuccessPage from './pages/PaymentSuccessPage.vue'
import PaymentFaliPage from './pages/PaymentFaliPage.vue'
import TextPage from './pages/TextPage.vue'
import Faq from './pages/Faq.vue'
import BuyCrypto from './pages/BuyCrypto.vue'
import OrderDetails from './pages/OrderDetails.vue'
import Confirmation from './pages/Confirmation.vue'
import OrderRequisites from "@/pages/OrderRequisites.vue";
import EmailAlreadyVerified from "./pages/EmailAlreadyVerified.vue";
import EmailVerificationNotice from "./pages/EmailVerificationNotice.vue";
import EmailVerificationSuccess from "./pages/EmailVerificationSuccess.vue";
import PaymentPendingPage from "./pages/PaymentPendingPage.vue";
// import eventBus from "./event_bus";

Vue.use(VueRouter)

const router =  new VueRouter({
	routes: [
		{ path: '/', redirect: '/home' },
		{
			path: '/home',
			name: 'Home',
			component: Home
		},
		{
			path: '/profile',
			name: 'Profile',
			component: Profile
		},
		{
			path: '/success-payment',
			name: 'PaymentSuccessPage',
			component: PaymentSuccessPage
		},
		{
			path: '/fail-payment',
			name: 'PaymentFaliPage',
			component: PaymentFaliPage
		},
		{
			path: '/processing-payment',
			name: 'PaymentPendingPage',
			component: PaymentPendingPage
		},
		{
			path: '/pages/:id',
			name: 'TextPage',
			component: TextPage
		},
		{
			path: '/faq',
			name: 'Faq',
			component: Faq
		},
		{
			path: '/buy-crypto',
			name: 'BuyCrypto',
			component: BuyCrypto
		},
		{
			path: '/order-details',
			name: 'OrderDetails',
			component: OrderDetails
		},
		{
			path: '/order-requisites/:uuid',
			name: 'OrderRequisites',
			component: OrderRequisites,
			meta: {
				requiresAuth: true, // This route requires authentication
			},
		},
		{
			path: '/confirmation',
			name: 'Confirmation',
			component: Confirmation
		},
		{
			path: "/email/verify/already-success",
			name: "EmailAlreadyVerified",
			component: EmailAlreadyVerified,
		},
		{
			path: "/email/verify/success",
			name: "EmailVerificationSuccess",
			component: EmailVerificationSuccess,
		},
		{
			path: "/email/verify/notice",
			name: "EmailVerificationNotice",
			component: EmailVerificationNotice,
		},
		{ 
			path: "*", 
			name: 'PageNotFound',
			component: PageNotFound,
			props: {title: "PageNotFound"}
		}
	]
});

router.beforeEach((to, from, next) => {
	// Check if the user is authenticated and their email is verified
	let isAuthenticated = JSON.parse(
		localStorage.getItem("isAuthenticated") || "false"
	);
	let emailVerified = JSON.parse(
		localStorage.getItem("emailVerified") || "false"
	);

	if (to.matched.some((record) => record.meta.requiresAuth)) {
		// This route requires authentication
		if (!isAuthenticated) {
			// User is not authenticated, redirect to the login page
			Vue.$toast.info("Please log in first");
			next({ name: "Home", query: { redirect: to.path } });
		} else if (!emailVerified) {
			// User's email is not verified, redirect to the email verification page
			Vue.$toast.info("Please verify your email");
			next({ name: "EmailVerificationNotice", query: { redirect: to.path } });
		} else {
			// User is authenticated and their email is verified, proceed to the route
			next();
		}
	} else {
		// This route does not require authentication, so proceed
		next();
	}
});

export default router;
